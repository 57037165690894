import { Environment } from '../../../environment.interface';

export const environment: Environment = {
  production: true,
  authApi: 'ozd',
  scope: 'admin',
  platform: 'web',

  appVersion: '1.2.0',

  apiBaseUrl: 'https://api.expertea.se/ee/api',
  imageBaseUrl: 'https://api.expertea.se/ee/api/v2/public/file',
  socketIoUrl: 'https://api.expertea.se',

  apiPageSize: 10,

  jwt: { refreshTokenNonce: 'A2UFZ8Jjxp' },

  publicUrl: 'adm.expertea.se',

  /** Used for Audo Playback as well */
  voiceRecording: {
    maxDurationInMs: 20000, // Unused in Web mode
    tickIntervalInMs: 200,
  },
};
